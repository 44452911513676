import { useEffect, useState } from "react";
import NextClasses from "../../components/NextClasses/NextClasses";
import "./Home.scss";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import useDates from "../../hooks/useDates";

export default function Home() {
  const [dates, setDates] = useState<string[]>([]);
  const { fetchFormatDate } = useDates();

  const generateListOfDates = () => {
    const tempArray = [];
    const nextDays = 15;

    for (let index = 0; index < nextDays; index++) {
      const newDate = new Date();
      newDate.setDate(newDate.getDate() + index);
      tempArray.push(fetchFormatDate(newDate.toISOString()));
    }

    setDates(tempArray);
  };

  useEffect(() => {
    generateListOfDates();
  }, []);

  return (
    <main className="flex-grow-1 d-flex flex-column">
      {dates.length ? <NextClasses nextDates={dates} /> : <CustomLoader />}
    </main>
  );
}
